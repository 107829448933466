
export function changeImageWhenAccordionOpen(){
	// This function is being use by the generic component accordion-with-content-change

	const accordionContentChangeList = document.querySelectorAll(".accordion-content-change");

	if (accordionContentChangeList){
		accordionContentChangeList.forEach(accordionContentChange => {
			const destination_selector = accordionContentChange.getAttribute("data-target");
			// Don't do anything if the destination element is not set
			if(destination_selector){
				const destination_element = document.querySelector(destination_selector);
				if(destination_element){
					const pass_content = (item) => {
						const content_to_pass = item.querySelector(".accordion-content-to-pass");
						if (content_to_pass){
							let cloned_content = content_to_pass.cloneNode(true);
							destination_element.innerHTML = cloned_content.innerHTML;
						}
					};
					// pass the copy of the content to the destination element
					accordionContentChange.addEventListener("shown.bs.collapse", event => {
						const item = event.target;
						pass_content(item);
					});
					// Empty the destination element when closed
					accordionContentChange.addEventListener("hide.bs.collapse", () => {
						destination_element.innerHTML = "";
					});

					// check if any element is already active upon page load
					// trigger the content pass if there's one
					const active_item = accordionContentChange.querySelector(".accordion-collapse.show");
					if(active_item){
						pass_content(active_item);
					}
				}
			}
		});
	}
}

window.addEventListener("DOMContentLoaded", changeImageWhenAccordionOpen);