import { logoScroller } from "@keodesign/logo-scroller";
import { ProportionalImageNormalisation } from "@keodesign/proportional-image-normalisation";
window.addEventListener("load", initialise);
  

function initialise() {
	// Proportionally size images to fit the scroller container.
	[...document.querySelectorAll(".scroller")].forEach((scroller) => {
		let width = scroller.dataset.imgAvgWidth;
		ProportionalImageNormalisation(".scroller .scroller-item", width, 0.525, scroller);
	});
	
	// Find any scroller elements, and load a @keodesign/logo-scroller instance for it.
	logoScroller(".scroller", ".scroller-item", "left", 0.1);
}
  