// Include the JS from any overridable components here.
// e.g. import "./sliders/slider.js";
import "./components/gallery/scroller.js";
import "./components/content_items/accordion-with-content-change.js";

import * as bootstrap from "bootstrap";


window.bootstrap = bootstrap;

export function addHoverToDropdown(){
	const btnGroups = document.querySelectorAll(".btn-group");
	if (btnGroups){
		btnGroups.forEach(btnGroup => {
			const dropdown = btnGroup.querySelector(".dropdown-toggle");
			if (dropdown){
				let bs_dropdown = bootstrap.Dropdown.getInstance(dropdown);
				if (!bs_dropdown) {
					bs_dropdown = new bootstrap.Dropdown(dropdown);
				}
				btnGroup.addEventListener("mouseover", () => {
					bs_dropdown.show();
				});
				btnGroup.addEventListener("mouseleave", () => {
					bs_dropdown.hide();
				});
			}
		});
	}
}


window.addEventListener("DOMContentLoaded", addHoverToDropdown);